import * as Sentry from "@sentry/browser";
import { EVENTS, track } from "@gemini-ui/analytics";
import { getAnalyticsIdFromCookie } from "@gemini-ui/analytics/mixpanel";
import { SHOW_INSTANT_TRADE_CARD } from "@gemini-ui/pages/Custody/Dashboard/DidYouKnow";
import { SignInFormValues } from "@gemini-ui/pages/SignIn/constants";
import axios from "@gemini-ui/services/axios";
import storage from "@gemini-ui/utils/storage";

const { SignInController } = jsRoutes.com.gemini.web.server.auth.controllers;
const makeSignInUrl = redirect => SignInController.post(redirect).url;
const trackLogin = (email: string) => {
  try {
    const analyticsId = getAnalyticsIdFromCookie();
    if (analyticsId) {
      const { name, properties } = EVENTS.SIGN_IN_ATTEMPT;
      track(name, { [properties.USER_ID]: analyticsId });
    } else {
      Sentry.captureMessage(`Unable to find AnalyticsId from cookie for ${email}!`);
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const makeSignInRequest = async (values: SignInFormValues, redirect: string) => {
  const {
    csrfToken,
    referral,
    email,
    password,
    rememberEmail,
    ["g-recaptcha-response-v2"]: recaptchaV2Response,
    ["g-recaptcha-response-v3"]: recaptchaV3Response,
  } = values;
  storage.set(SHOW_INSTANT_TRADE_CARD, true);

  await axios.post(makeSignInUrl(redirect), {
    csrfToken: csrfToken,
    referral,
    email,
    password: password,
    rememberEmail: rememberEmail,
    "validationIds.g-recaptcha-response-v2": recaptchaV2Response,
    "validationIds.g-recaptcha-response-v3": recaptchaV3Response,
  });
  trackLogin(values.email);
  window.location.reload();
};
export const handleExceptionWithRedirect = (exception, email: string) => {
  // Case where the redirect url is a guarded page and the user needs to 2FA before accessing
  // This results in a red error message blocking the user from logging in
  // This temp fix refreshes the page and lets the BE handle the correct route to the 2FA if applicable
  if (exception.response?.data?.error === "noAccess" && exception.response?.config?.url) {
    window.location.href = exception.response.config.url;
    return true;
  } else if (
    exception.response?.data?.error === "needs2fa" ||
    exception.response?.data?.error === "needsUserPolicyConsentResponse"
  ) {
    // 2fa is not actually an exception
    trackLogin(email);
    window.location.href = exception.response.data.redirect;
    return true;
  } else {
    return false;
  }
};
